<template>
  <footer class="bg-gray-600 px-4">
    <div
      class="main container mx-auto flex flex-col md:flex-row relative justify-between py-10"
    >
      <a id="to-top" href="#">
        <img src="/img/icons/to-top.png" />
      </a>
      <div class="md:1/4">
        <h5 class="text-white">{{ $t('about_milsped') }}</h5>
        <ul>
          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'about-history'
                })
              "
            >
              {{ $t('history') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'about-numbers'
                })
              "
            >
              {{ $t('numbers_and_facts') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'about-certificates'
                })
              "
            >
              {{ $t('certificates') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link :to="localePath('gallery')">
              {{ $t('gallery') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="md:1/4">
        <h5 class="text-white">{{ $t('OUR_SOLUTIONS') }}</h5>
        <ul>
          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'solution-slug',
                  params: { slug: 'freight-transport' }
                })
              "
            >
              {{ $t('ftl') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'solution-slug',
                  params: { slug: 'contract-logistics' }
                })
              "
            >
              {{ $t('warehouse') }}
            </nuxt-link>
          </li>

          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'solution-slug',
                  params: { slug: 'sar-transport' }
                })
              "
            >
              {{ $t('sar_transport') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'solution-slug',
                  params: { slug: 'industries' }
                })
              "
            >
              {{ $t('industries') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                localePath({
                  name: 'solution-automotive-logistics'
                })
              "
            >
              {{ $t('automotive_logistics') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="md:1/4">
        <h5 class="text-white">{{ $t('GLOBAL_PRESENCE') }}</h5>
        <ul>
          <li>
            <nuxt-link
              :to="localePath('global-presence')"
              class="text-gray-100"
            >
              {{ $t('europe') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath('global-presence')"
              class="text-gray-100"
            >
              {{ $t('asia') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath('global-presence')"
              class="text-gray-100"
            >
              {{ $t('america') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="localePath('local-information')"
              class="text-gray-100"
            >
              {{ $t('LOCAL_INFORMATION') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="md:1/4">
        <h5 class="text-white">{{ $t('info_and_news') }}</h5>
        <ul>
          <li>
            <nuxt-link :to="localePath('contact')" class="text-gray-100">
              {{ $t('contact') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link :to="localePath('career')" class="text-gray-100">
              {{ $t('career') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link :to="localePath('news')" class="text-gray-100">
              {{ $t('news') }}
            </nuxt-link>
          </li>
          <li>
            <a
              :href="`/terms-and-conditions-${$i18n.locale}.pdf`"
              target="_blank"
              >{{ $t('terms_and_conditions') }}</a
            >
          </li>
          <li>
            <nuxt-link :to="localePath('privacy-policy')">
              {{ $t('privacy') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="container mx-auto flex pt-5 pb-5 justify-between">
      <div>
        <img src="/img/logo2.png" />
        <p class="text-gray-100 mt-4">
          Copyright Milšped - All Rights Reserved
        </p>
      </div>
      <div class="flex social">
        <!-- <a
          class="w-16 cursor-pointer"
          target="_blank"
          href="http://aeaprd.webtracker.wisegrid.net/"
        >
          <img class="h-6 w-auto" src="/img/logo-web-tracker.gif" />
        </a> -->
        <a target="_blank" href="https://www.instagram.com/milsped_group">
          <v-icon class="icon" name="instagram"></v-icon>
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/mil-ped">
          <v-icon class="icon" name="linkedin"></v-icon>
        </a>
        <a target="_blank" href="https://www.facebook.com/Milsped/">
          <v-icon class="icon" name="facebook"></v-icon>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCUloQfgEERbGPQZB4Qpg1lA"
        >
          <v-icon class="icon" name="youtube"></v-icon>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style scoped>
.main {
  border-bottom: 1px solid theme('colors.gray.200');
}
h5 {
  text-transform: uppercase;
  @apply font-semibold;
}
li,
li a {
  @apply text-gray-100;
}
li a:hover {
  text-decoration: underline;
}
footer .main div {
  @apply mb-4;
}
.social .icon {
  @apply text-gray-100;
  width: 24px;
  margin-left: 15px;
}
#to-top {
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  transform: translateY(-50%);
}
.chevron {
  height: 40px;
}
</style>
